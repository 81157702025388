import { ModuleWithProviders, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CataleanWishlistConfig } from 'catalean-models';

@NgModule({
  declarations: [],
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule],
  exports: [],
  providers: [TranslatePipe],
})
export class CataleanWishlistModule {
  static forRoot(config: CataleanWishlistConfig): ModuleWithProviders<CataleanWishlistModule> {
    return {
      ngModule: CataleanWishlistModule,
    };
  }
}
