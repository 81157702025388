import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Parts } from 'catalean-models';
import { SmartHttpModuleService, UrlBuilderService } from 'catalean-provider';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { SmartIconComponent } from 'src/app/components/smart-icon/smart-icon';
import { CompleteShare, ShareViewList } from 'src/app/models/share';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sharing-archive',
  templateUrl: './sharing-archive.component.html',
  styleUrls: ['./sharing-archive.component.scss'],
  standalone: true,
  imports: [TranslateModule, CommonModule, SmartIconComponent, FormsModule],
  providers: [DatePipe],
})
export class SharingArchiveComponent {
  searchTerm: FormControl = new FormControl();

  searchSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private requestUrl = new UrlBuilderService(environment.shareLinkUrl)
    .withQueryParam('organizationUUID', environment.organizationUUID)
    .withParts(Parts.SHARE_DATA)
    .build();

  shareObject$ = this.smartHttpModule.get<Array<CompleteShare>>(this.requestUrl).pipe(
    map((shareArchive) => {
      return shareArchive.map((x) => {
        const shareObject: ShareViewList = {
          name: x.name,
          email: x.user?.length ? x.user?.[0]?.email : x.applicationUser?.[0]?.email,
          date: this.dataPipe.transform(new Date(x.timestamp), 'dd/MM/yyyy'),
          surname: x.surname,
          product: x.product?.[0],
          wishlist: x.wishlist?.[0],
          filters: x.filters,
        };
        return shareObject;
      });
    }),

    switchMap((shareArchive) =>
      this.searchSubject$.pipe(
        map((term: string) => {
          if (!term || term.trim().length === 0) {
            return shareArchive;
          } else {
            return this.search(term, shareArchive);
          }
        })
      )
    )
  );

  constructor(private smartHttpModule: SmartHttpModuleService, private dataPipe: DatePipe, private modalController: ModalController) {}

  search(term: string, shareArchive: ShareViewList[]): ShareViewList[] {
    return shareArchive.filter((share) => {
      const fullName = (share.name ? share.name.toLowerCase() : '') + ' ' + (share.surname ? share.surname.toLowerCase() : '');
      return fullName.includes(term.toLowerCase()) || (share.email && share.email.toLowerCase().includes(term.toLowerCase()));
    });
  }
  onSearch(event) {
    if (typeof event == 'string') {
      this.searchSubject$.next(event);
    } else {
      this.searchSubject$.next(event.target.value);
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
