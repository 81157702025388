import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { UserSettingsService } from 'catalean-authentication';
import { Product, StructureType, Wishlist } from 'catalean-models';
import { DataleanDataProviderService, ProductManagerService } from 'catalean-provider';
import { CataleanStorageService } from 'catalean-storage';
import { BehaviorSubject, Observable, combineLatest, forkJoin, from, of, timer } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CataleanWishlistService {
  readonly WISHLIST_STORAGE_KEY = 'wishlists';

  private status$ = new BehaviorSubject<'uninitialized' | 'loading' | 'error' | 'loaded'>('uninitialized');
  private lastError$ = new BehaviorSubject<string>(undefined);

  private wishlists$ = new BehaviorSubject<Wishlist[]>([]);
  lastChosenWishlistName$ = new BehaviorSubject('');

  chosenWishlist$: Observable<Wishlist> = combineLatest([this.wishlists$, this.lastChosenWishlistName$]).pipe(
    map(([wishlists, lastChosenWishlistName]) => {
      const lastChoosenWishlist = wishlists.find((w) => w.name === lastChosenWishlistName) ?? wishlists[0]
      if (lastChoosenWishlist) {
        return lastChoosenWishlist;
      } else {
        this.lastChosenWishlistName$.next(wishlists[0]?.name ?? '');
        return wishlists[0];
      }
    })
  );

  constructor(
    private dataleanServiceProvider: DataleanDataProviderService,
    private userSettingsService: UserSettingsService,
    private productService: ProductManagerService,
    private cataleanStorage: CataleanStorageService,
    private translatePipe: TranslatePipe
  ) {}

  getStatus() {
    return this.status$.asObservable();
  }

  getWishlists(): Observable<Wishlist[]> {
    return combineLatest([this.wishlists$, this.chosenWishlist$]).pipe(
      switchMap(([ wishlists, chosenWishlist]) => {
        return of(
          wishlists.map((w) => {
            if (w.name === chosenWishlist?.name) {
              const wishlist = structuredClone(w);
              wishlist.chosen = true;
              return wishlist;
            }
            return w;
          })
        );
      })
    );
  }

  getWishlist(uuid: string) {
    return this.wishlists$.pipe(map((ws) => ws.find((w) => w.uuid === uuid)));
  }

  getWishlistWithProducts(uuid) {
    return combineLatest([this.wishlists$, this.chosenWishlist$])
    .pipe(map(([wishlists, chosenWishilist]) => {
      const wishlist = wishlists.find((w) => w.uuid === uuid);
      if(wishlist) {
        if(wishlist.name === chosenWishilist?.name) {
          wishlist.chosen = true;
        }
        wishlist.products = (wishlist.productsRef ?? []).reduce((acc, pUUID) => {
          const product = this.productService.getProduct(pUUID.uuid);
          if (product) acc.push(product);
          return acc;
        }, []);
      }

      return wishlist;
    }))
  }

  setActiveWishlist(w?: Wishlist) {
      this.cataleanStorage.setRow('lastWishlist', w.name);
      this.lastChosenWishlistName$.next(w.name);
  }

  private setError(e): void {
    this.status$.next('error');
    this.lastError$.next(e);
  }

  init(): Observable<void> {
    return this.status$.pipe(
      first(),
      switchMap((status) =>
        from(this.cataleanStorage.getRow('lastWishlist')).pipe(map((lastWishlistName) => ({ status, lastWishlistName })))
      ),
      switchMap(({ status, lastWishlistName }) => {
        this.lastChosenWishlistName$.next(lastWishlistName);
        switch (status) {
          case 'error':
          case 'uninitialized': {
            this.status$.next('loading');
            return this.syncRemote().pipe(
              map((response) => {
                this.status$.next('loaded');
                return response;
              }),
              catchError((e) => {
                this.setError(e);
                return of(e);
              })
            );
          }
          default:
            return of(undefined);
        }
      })
    );
  }

  setWishlists(wishlists: Wishlist[]) {
    this.wishlists$.next(wishlists);
  }

  deleteAll(): Observable<Wishlist[]> {
    this.status$.next('loading');
    const wlUUIDsToRemove = this.wishlists$.value.reduce((pv, cv) => {
      if (!cv.isPublic) {
        pv.push(cv.uuid);
      }
      return pv;
    }, []);
    if (wlUUIDsToRemove.length) {
      return this.dataleanServiceProvider.deleteWishlists(wlUUIDsToRemove).pipe(
        catchError((err) => {
          return of(true);
        }),
        switchMap(() => this.syncRemote()),
        tap(() => this.setActiveWishlist(this.wishlists$.value[0])),
        switchMap(() => this.wishlists$),
        first(),
        catchError((e) => {
          this.setError(e);
          throw new Error(e.message);
        })
      );
    }
    return this.wishlists$.pipe(first());
  }

  private syncRemote(): Observable<Wishlist[]> {
    const updateWishlists$ = (wls: Wishlist[], remoteWlList: Wishlist[]): Observable<(void | Wishlist)[]> => {
      //mi baso sui nomi invece che sugli uuid perchè in caso di creazioni potrei non avere l'uuid locale dato che è generalo dal be
      const wlsToCreate = wls.filter(
        (local) => !remoteWlList.some((remote) => remote.name === local.name || local.isPublic) && local.needToSync
      );
      const wlsToUpdate = wls.filter(
        (local) => remoteWlList.some((remote) => remote.name === local.name && !remote.isPublic) && local.needToSync
      );
      const obsArray: Observable<void | Wishlist>[] = [
        from(this.cataleanStorage.getRow<string[]>('wishlistsToRemove')).pipe(
          switchMap((wlsToRemove) => {
            if (wlsToRemove) {
              return this._deleteWishlists(wlsToRemove);
            }
            return of([]);
          }),
          map(() => {
            this.cataleanStorage.setRow('wishlistsToRemove', undefined);
          })
        ),
      ];
      for (const wlToCreate of wlsToCreate) {
        obsArray.push(this.createWishlist(this.fixWishlistForCU(wlToCreate)));
      }
      for (const wlToUpdate of wlsToUpdate) {
        obsArray.push(this._updateWishlist(this.fixWishlistForCU(wlToUpdate)));
      }
      return obsArray.length ? forkJoin(obsArray) : of(null);
    };

    return this.fetchWishlists().pipe(
      switchMap((remoteWishlists) =>
        from(this.cataleanStorage.getRow<Wishlist[]>(this.WISHLIST_STORAGE_KEY)).pipe(
          switchMap((wls: Wishlist[]) => {
            if (wls) {
              return updateWishlists$(wls, remoteWishlists).pipe(switchMap(() => this.fetchWishlists()));
            }
            return of(remoteWishlists);
          }),
          map((response) => {
            this.setLocalWishlists(response);
            return response;
          })
        )
      ),
      catchError((e) => from(this.cataleanStorage.getRow<Wishlist[]>(this.WISHLIST_STORAGE_KEY)))
    );
  }

  private fetchWishlists() {
    return this.dataleanServiceProvider.getWishlists(this.userSettingsService.getUserUUID()).pipe(
      map((wlList) =>
        wlList.map((wl) => {
          wl.name = wl.name.replace(`${this.userSettingsService.getUserUUID()}_`, '');
          return wl;
        })
      )
    );
  }

  private createWishlist(wishlist: Wishlist): Observable<Wishlist> {
    this.status$.next('loading');
    return this._createWishlist(wishlist).pipe(
      switchMap((wl) => {
        return this.dataleanServiceProvider
          .addViewPermissionToUserOnEntity(this.userSettingsService.getUserUUID(), wl.uuid, StructureType.WISHLIST)
          .pipe(map(() => ({ ...wl })));
      })
    );
  }
  private _createWishlist(wishlist: Wishlist): Observable<Wishlist> {
    return this.dataleanServiceProvider.createWishlist(this.fixWishlistForCU(wishlist));
  }

  updateWishlist(wishlist: Wishlist): Observable<Wishlist> {
    this.status$.next('loading');
    if (!wishlist.uuid) {
      return this.createWishlist(wishlist).pipe(
        map((createdWishlist) => {
          createdWishlist.name = createdWishlist.name.replace(`${this.userSettingsService.getUserUUID()}_`, '');
          // this.setLocalWishlists([...this.wishlists$.value, createdWishlist]);
          return createdWishlist;
        }),
        catchError((err: HttpErrorResponse) => {
          if (err?.error?.Errors?.[0]?.message.includes('duplicate key error')) {
            return this.dataleanServiceProvider.getWishlistByName(wishlist.name, this.userSettingsService.getUserUUID());
          }
          const alreadyPresentWishlist = this.wishlists$.value.some((wl) => wishlist.name === wl.name);
          if (!alreadyPresentWishlist) {
            wishlist.needToSync = true;
            this.setLocalWishlists([...this.wishlists$.value, wishlist]);
          }
          return of(wishlist);
        }),
        switchMap((wl) => this.syncRemote().pipe(map(() => wl))),
        tap((wl) => {
          this.setActiveWishlist(wl);
        })
      );
    }

    return this._updateWishlist(wishlist).pipe(
      map(() => {
        const currentWls = this.wishlists$.value.map((wl) => {
          if (wl.uuid === wishlist.uuid) {
            return wishlist;
          }
          return wl;
        });
        return wishlist;
      }),
      catchError((err) => {
        const currentWls = this.wishlists$.value.map((wl) => {
          if (wl.uuid === wishlist.uuid) {
            return wishlist;
          }
          wl.needToSync = true;
          return wl;
        });
        this.setLocalWishlists(currentWls);
        return of(wishlist);
      }),
      switchMap((wl) => this.syncRemote().pipe(map(() => wl)))
    );
  }

  cloneWishlistInstant(wishlist: Wishlist): void {
    this.cloneWishlist(wishlist).pipe(first()).subscribe();
  }
  cloneWishlist(wishlist: Wishlist): Observable<Wishlist> {
    const newWishlist = structuredClone(wishlist);
    delete newWishlist.uuid;
    wishlist.isPublic = false;
    newWishlist.name = `${newWishlist.name} ${this.translatePipe.transform('WISHLIST.CLONE_NAME')}`;
    return this.updateWishlist(newWishlist);
  }

  private _updateWishlist(wishlist: Wishlist): Observable<Wishlist> {
    return this.dataleanServiceProvider.updateWishlist(this.fixWishlistForCU(wishlist));
  }

  deleteWishlist(wishlist: Wishlist): Observable<void> {
    if (!wishlist.isPublic) {
      return this._deleteWishlists([wishlist.uuid]).pipe(
        map(() => {
          return wishlist;
        }),
        catchError((err) => {
          this.setLocalWishlists(this.wishlists$.value.filter((wl) => wl.uuid !== wishlist.uuid));
          return from(this.cataleanStorage.getRow<string[]>('wishlistToRemove')).pipe(
            switchMap((currentWlToRemove) => {
              if (currentWlToRemove) {
                currentWlToRemove.push(wishlist.uuid);
                return from(this.cataleanStorage.setRow('wishlistToRemove', currentWlToRemove));
              }
              return from(this.cataleanStorage.setRow('wishlistToRemove', [wishlist.uuid]));
            })
          );
        }),
        switchMap(() => this.syncRemote().pipe(map(() => {}))),
        tap(() => {
          if (this.lastChosenWishlistName$.value === wishlist.name) {
            this.setActiveWishlist(this.wishlists$.value[0]);
          }
        })
      );
    }
    return of();
  }

  private _deleteWishlists(wishlistUUIDs: string[]): Observable<void> {
    return this.dataleanServiceProvider.deleteWishlists(wishlistUUIDs);
  }

  removeProductInstant(product: Product, wishlist: Wishlist) {
    this.removeProduct(product, wishlist).pipe(first()).subscribe();
  }
  removeProduct(product: Product, wishlist: Wishlist): Observable<void> {
    return this.dataleanServiceProvider.removeEntitiesFromWishlist([product.uuid], wishlist.uuid).pipe(
      map(() => {
        const updatedWishlists = this.wishlists$.value.map((wl) => {
          if (wl.uuid === wishlist.uuid) {
            const uuidIndex = wl.productsRef.findIndex((pr) => pr.uuid === product.uuid);
            if (uuidIndex > -1) {
              // only splice array when item is found
              wl.productsRef.splice(uuidIndex, 1); // 2nd parameter means remove one item only
            }
          }
          return wl;
        });
      }),
      catchError(() => {
        const updatedWishlists = this.wishlists$.value.map((wl) => {
          if (wl.uuid === wishlist.uuid) {
            const uuidIndex = wl.productsRef.findIndex((pr) => pr.uuid === product.uuid);
            if (uuidIndex > -1) {
              // only splice array when item is found
              wl.productsRef.splice(uuidIndex, 1); // 2nd parameter means remove one item only
            }
            wl.needToSync = true;
          }
          return wl;
        });
        this.setLocalWishlists(updatedWishlists);
        return of(true);
      }),
      switchMap(() => this.syncRemote().pipe(map(() => {}))),
      catchError((e) => {
        this.setError(e);
        throw new Error(e.message);
      })
    );
  }

  addProductInstant(product: Product, wishlist: Wishlist) {
    this.addProduct(product, wishlist).pipe(first()).subscribe();
  }
  addProduct(product: Product, wishlist: Wishlist): Observable<void> {
    return this.dataleanServiceProvider.addEntityToWishlist([{ uuid: product.uuid }], wishlist.uuid).pipe(
      map(() => {
        const updatedWishlists = this.wishlists$.value.map((wl) => {
          if (wl.uuid === wishlist.uuid) {
            wl.productsRef.push({ uuid: product.uuid });
          }
          return wl;
        });
      }),
      catchError(() => {
        const updatedWishlists = this.wishlists$.value.map((wl) => {
          if (wl.uuid === wishlist.uuid) {
            wl.productsRef.push({ uuid: product.uuid });
            wl.needToSync = true;
          }
          return wl;
        });
        this.setLocalWishlists(updatedWishlists);
        return of(true);
      }),
      switchMap(() => this.syncRemote().pipe(map(() => {}))),
      catchError((e) => {
        this.setError(e);
        throw new Error(e.message);
      })
    );
  }

  generateNewWishlist(): Observable<Wishlist> {
    const lastIndex = this.wishlists$.value.reduce((acc, obj) => {
      if (obj.name.startsWith('Wishlist #')) {
        const i = parseInt(obj.name.slice(-1));
        if (acc <= i) {
          acc = i + 1;
        }
      }
      return acc;
    }, 0);
    return of({
      isPublic: false,
      name: `Wishlist #${lastIndex}`,
      productsRef: [],
      chosen: false,
    });
  }

  private fixWishlistForCU(wishlist: Wishlist): Wishlist {
    const copiedWishlist = structuredClone(wishlist);
    if (!copiedWishlist.name.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}_'))
      copiedWishlist.name = `${this.userSettingsService.getUserUUID()}_${wishlist.name}`;
    delete copiedWishlist.needToSync;
    delete copiedWishlist.chosen;
    delete copiedWishlist.products;
    return copiedWishlist;
  }

  private setLocalWishlists(wishlists: Wishlist[]) {
    this.wishlists$.next(wishlists);
    this.cataleanStorage.setRow(this.WISHLIST_STORAGE_KEY, wishlists);
  }
}
