// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// dev

export const environment = {
  production: false,
  organizationPrefix: 'CAV',
  organizationUUID: '3850749f-37c1-451a-846d-4d89ec01928b',
  accountId: 'cavanna-2-dev',
  authenticationUrl: 'https://auth.datalean-nodejs-preprod.catalean.com/',
  configUrl: 'https://configuration.datalean-nodejs-preprod.catalean.com/',
  // authenticationUrl: 'http://localhost:3020/',
  cataleanCacheUrl: 'https://catbnddev.addictionlab.it/v1/',
  productsUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/product/v2/',
  wishlistUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/wishlist/',
  usersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/application/',
  userStructureUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/applicationUserStructure/',
  structuresUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structure/v2/',
  organizationUrl: 'https://organization.datalean-nodejs-preprod.catalean.com/organization/',
  shareLinkUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/share/',
  shareLinkParseUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/share/parse',
  templateUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/template/',
  defaultLocale: 'en-US',
  version: '1.3.0',
  availableLocales: ['en-US'],
  mappingUUID: '41350706-b3db-4f0d-bb30-60d34ec5ae58',
  uuidParameterType: 'MAP_CONTAINER_UUID',
  // 1: normal logout, 2: overwrite check assets to delete on next login, 3: delete assets on logout
  logoutDataRetentionMode: 1,
  resourceBatchLimit: 10,
  catalean: {
    version: 1,
    uuid: '2a2b5733-8658-4127-89ae-07b05a611743',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
