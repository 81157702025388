import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { CataleanLocalizationService } from 'catalean-localization';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';
import { DeviceResourcesService } from 'catalean-local-files';
import { UserSettingsService } from 'catalean-authentication';
import { CataleanAuthenticationService } from 'catalean-authentication';
import { FilterManagerService } from 'catalean-provider';
import { CataleanConfigService } from '../../projects/catalean-configuration/src/lib/catalean-config.service';
import { CurrentPlatformService } from './services/current-platform.service';
import { App } from '@capacitor/app';
import { SharingArchiveComponent } from './components/sharing-archive/sharing-archive.component';
import { CavannaIntegration } from './models/cavanna-integration';

const env = environment;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public readonly VERSION_PREFIX: string = 'v';
  public version = '';
  public openMenuSubject = new Subject<void>();
  isMobile: boolean;

  rootFilterChildren$ = this.filterService.getRootFilterChildren();
  activeFilter$ = this.filterService.getSelectedMetroFilter();
  rootFilter$ = this.filterService.getRootFilter();
  activatedRoute: ActivatedRoute;
  constructor(
    public platform: CurrentPlatformService,
    private userSettingsService: UserSettingsService,
    private router: Router,
    private menuController: MenuController,
    private localizationService: CataleanLocalizationService,
    private navCtrl: NavController,
    private deviceResourcesService: DeviceResourcesService,
    private authService: CataleanAuthenticationService,
    private filterService: FilterManagerService,
    private configService: CataleanConfigService<CavannaIntegration>,
    private modalController: ModalController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.localizationService.setActiveLanguage(env.defaultLocale);
      this.localizationService.AvailableLocales = env.availableLocales;
    });

    this.platform.onResizeWindows.subscribe((data) => {
      const {width, height} = data
      if (/Android|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true;
      } else {
        if (width < 1024) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }
      if (!environment.production) {
        this.isMobile = false;
      }
    })
  }

  ngOnInit() {
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        App.getInfo()
          .then(({ version }) => {
            this.version = this.VERSION_PREFIX + version;
          })
          .catch((err) => {
            console.log(err);
            this.version = this.VERSION_PREFIX + environment.version;
          });

        this.router.navigate([location.pathname.includes('/catalean-share') ? location.pathname.split('/') : '']);
      });
    } else {
      this.version = this.VERSION_PREFIX + environment.version;
      this.router.navigate([location.pathname.includes('/catalean-share') ? location.pathname.split('/') : '']);
    }
  }

  goTo(filter) {
    this.navCtrl.navigateRoot(['metro']).then(() => {
      if (filter.children?.some((c) => c.align === 'metro')) {
        this.filterService.setSelectedMetroFilter(filter);
        this.navCtrl.navigateForward(['metro', filter.name]);
        return;
      }
      if (filter.entityType.length === 1) {
        this.filterService.setSelectedMetroFilter(filter);
        if (filter.entityType.includes('product')) {
          this.navCtrl.navigateForward(['product-grid']);
          return;
        }
        if (filter.entityType.includes('content')) {
          const cataleanConfig = this.configService.getCataleanValue()
          if (filter.featureId === cataleanConfig['cavanna-group'].featureUUID) {
            this.navCtrl.navigateForward(['cavanna-group']);
            return;
          }
          if (filter.featureId === cataleanConfig.events.featureUUID) {
            this.navCtrl.navigateForward(['events']);
            return;
          }
          if (filter.featureId === cataleanConfig.news.featureUUID) {
            this.navCtrl.navigateForward(['news']);
            return;
          }
          if (filter.featureId === cataleanConfig.services.featureUUID) {
            this.navCtrl.navigateForward(['services']);
            return;
          }
            this.navCtrl.navigateForward(['worksheet'], { relativeTo: this.activatedRoute });
            return
        }
      }
      if (filter.entityType.includes('product')) {
      } else {
        console.log('metro -> navigate to ', filter.name);
      }
    });
    this.menuController.close();
  }

  async openSharingArchive() {
    const modal = await this.modalController.create({
      component: SharingArchiveComponent,
      cssClass: "sharing-archive-modal",
      backdropDismiss: false,
    });
    await modal.present();
  }

  logout() {
    this.deviceResourcesService.StopDownload = true;
    if (environment.logoutDataRetentionMode === 3) {
      this.deviceResourcesService.deleteAllAssets();
    }
    this.authService.logout();
  }

  onMenuOpen() {
    this.openMenuSubject.next();
  }
}
