import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'product-grid',
    loadChildren: () => import('./pages/product-grid/product-grid.module').then((m) => m.ProductGridPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'metro',
    loadChildren: () => import('./pages/metro/metro.module').then((m) => m.MetroPageModule),
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then((m) => m.ProductDetailPageModule),
  },
  {
    path: 'cavanna-group',
    loadChildren: () => import('./pages/cavanna-group/cavanna-group.module').then((m) => m.CavannaGroupModule),
  },
  {
    path: 'search-results/:text',
    loadChildren: () => import('./pages/search-page/search-results.module').then((m) => m.SearchResultsPageModule),
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then((m) => m.NewsModule),
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/services/service.module').then((m) => m.ServicePageModule),
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsPageModule),
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then((m) => m.WishlistPageModule),
  },
  {
    path: 'worksheet',
    loadChildren: () => import('./pages/worksheet/worksheet.module').then((m) => m.WorksheetPageModule),
  },
  {
    path: 'catalean-share',
    loadChildren: () => import('./catalean-share/catalean-share.module').then((m) => m.CataleanSharePageModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
