import {AuthConfig, OAuthModuleConfig} from 'angular-oauth2-oidc';
import {environment} from "../environments/environment";

export const authConfig: AuthConfig = {
    issuer: environment.authenticationUrl,
    customQueryParams: {
        organizationUUID: environment.organizationUUID,
        userType: "ApplicationUser@cataleanUser",
        prompt: "login"
    },
    oidc:true,
    requireHttps: environment.production,
    clientId: 'cavanna-integration', // The "Auth Code + PKCE" client
    responseType: 'code',
    redirectUri: window['cordova'] ? 'it.addiction.catalean.cavanna://' : window.location.origin + '/',
    logoutUrl: window['cordova'] ? 'it.addiction.catalean.cavanna://' : window.location.origin + '/',
    silentRefreshRedirectUri: window['cordova'] ? `it.addiction.catalean.cavanna://-/silent-refresh.html` : `${window.location.origin}/silent-refresh.html`,
    scope: 'openid profile email offline_access',
    timeoutFactor: 0.75, // For faster testing
    sessionChecksEnabled: false,
    showDebugInformation: !environment.production,
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
    // openUri: (uri) => {
    //     window['cordova'] ?  window.open(uri): location.href = uri
    // }
};

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [environment.productsUrl, environment.structuresUrl, environment.wishlistUrl],
    sendAccessToken: true,
  }
};
