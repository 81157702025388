<div class="sharing-archive-container">
  <div class="title-container">
    <h1 class="title">{{"SHARING_ARCHIVE.TITLE" | translate}}</h1>
    <div class="search-container">
      <smart-icon name="f" (click)="onSearch(input.value)"></smart-icon>
      <input class="search" #input (input)="onSearch($event)" [placeholder]=" 'SHARING_ARCHIVE.SEARCH_PLACEHOLDER' | translate ">
    </div>
    <smart-icon name="b" (click)="dismiss()"></smart-icon>
  </div>
  <div class="table-container">
    <div class="table-header">
      <div class="table-column email">{{"SHARING_ARCHIVE.TABLE_HEADER.E-MAIL" | translate}}</div>
      <div class="table-column">{{"SHARING_ARCHIVE.TABLE_HEADER.NAME_SURNAME" | translate}}</div>
      <div class="table-column">{{"SHARING_ARCHIVE.TABLE_HEADER.SHARED_MATERIAL" | translate}}</div>
      <div class="table-column date">{{"SHARING_ARCHIVE.TABLE_HEADER.DATE" | translate}}</div>
    </div>
    <div class="table-content">
      <div class="table-row" *ngFor="let item of shareObject$ | async">
        <div class="table-column email" >{{item.email}}</div>
        <div class="table-column">{{item.name}} {{item.surname}}</div>
        <div class="table-column" *ngIf="item.product">{{item.product?.name}}</div>
        <div class="table-column date">{{item.date}}</div>
      </div>
    </div>
  </div>
</div>
