import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'wishlist-presentation-header',
  templateUrl: './wishlist-presentation-header.component.html',
  standalone:true
})
export class WishlistPresentationHeaderComponent implements OnInit {
  @Input() logo;
  @Input() length = 1;
  @Output() close = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();
  
  activeIndex = 1;

  constructor() {}

  ngOnInit(): void {
  }

  onClose() {
    this.close.emit();
  }

  onNext() {
    if(this.activeIndex < this.length) {
      this.activeIndex++;
      this.next.emit(this.activeIndex);
    }

  }

  onPrev() {
    if(this.activeIndex > 1) {
      this.activeIndex--;
      this.prev.emit(this.activeIndex);
    }
  }
}
