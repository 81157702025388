import { User, UserBuilder, AuthenticationToken, Parts, StructureType, EnvironmentConfiguration, Structure, Collection, DataleanUpdateEntityResponse, AlbumUpdateBody } from 'catalean-models';
import { UrlBuilderService } from './services/url-builder.service';
import { Observable } from 'rxjs';
import { SmartHttpModuleService } from './services/smart-http-module.service';
import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Wishlist } from 'catalean-models';

@Injectable({
  providedIn: 'root',
})
export class DataleanDataProviderService {
  private readonly CATALEAN_USER_FIELD_TYPE = 'APPLICATION_USER';
  private readonly CATALEAN_USER_FIELD_KEY = 'userType';
  private readonly ORGANIZATION_PREFIX_URL_FIELD_KEY = '?organizationPrefix=';
  private readonly ORGANIZATION_URL_FIELD_KEY = '?organizationUUID=';
  public readonly FEEDBACK_TYPE_HAS_LOGGED_KEY = 'has_logged';
  public readonly FEEDBACK_TYPE_HAS_ACCESSED_KEY = 'has_accessed';
  readonly userGetParts = [Parts.VALUES, Parts.SCORE, Parts.COMMUNITY, Parts.AUTHENTICATION_METHODS];

  constructor(@Inject('env') private environmentSettings: EnvironmentConfiguration, private smartHttpModule: SmartHttpModuleService) {}

  saveGeneralFeedback(data: any): Observable<any> {
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.generalFeedbackUrl +
        data.entityUUID +
        '/feedback' +
        '?organizationUUID=' +
        this.environmentSettings.organizationUUID
    )
      .withParts(Parts.ALL)
      .build();

    return this.smartHttpModule.post(requestUrl, data);
  }

  login(username: string, password: string): Observable<AuthenticationToken> {
    const data = {
      username,
      password,
      cataleanUUID: this.environmentSettings.catalean.uuid,
    };
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.authenticationUrl + this.ORGANIZATION_PREFIX_URL_FIELD_KEY + this.environmentSettings.organizationPrefix
    )
      .withQueryParam(this.CATALEAN_USER_FIELD_KEY, this.CATALEAN_USER_FIELD_TYPE)
      .build();

    return this.smartHttpModule.post(requestUrl, data).pipe(map((data) => this.mapAuthenticationData(data)));
  }

  getUserWithUUID(userUUID: string): Observable<User> {
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.usersUrl + userUUID + '?organizationUUID=' + this.environmentSettings.organizationUUID
    )
      .withParts(this.userGetParts.join(','))
      .build();

    return this.smartHttpModule.get(requestUrl).pipe(map((data) => this.mapUserData(data)));
  }

  getUserByFieldAndValue(fieldName: string, fieldValue: string): Observable<User> {
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.usersUrl + '?organizationUUID=' + this.environmentSettings.organizationUUID
    )
      .withParts(Parts.VALUES)
      .withQueryParam('query', fieldValue)
      .withQueryParam('searchFields', fieldName)
      .build();

    return this.smartHttpModule.get<any>(requestUrl).pipe(map((userData) => this.mapUserData(userData.result[0])));
  }

  // require system user
  postUserUUIDForPasswordRecovery(userUUID: string): Observable<any> {
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.usersUrl + userUUID + '/reset-password' + '?organizationUUID=' + this.environmentSettings.organizationUUID
    ).build();
    const data = {
      uuid: userUUID,
    };
    return this.smartHttpModule.post(requestUrl, data);
  }

  private mapUserData(responseData: any): User {
    const id = responseData[User.UUID_FIELD_KEY];
    const firstName = responseData[User.FIRST_NAME_FIELD_KEY];
    const lastName = responseData[User.LAST_NAME_FIELD_KEY];
    const username = responseData[User.USERNAME_FIELD_KEY];
    const email = responseData[User.EMAIL_FIELD_KEY];
    const avatarImageURL = responseData[User.AVATAR_IMAGE_URL_FIELD_KEY];
    const resetDeviceIdentifier = responseData[User.RESET_DEVICE_IDENTIFIER_FIELD_KEY];
    const structureUUID = responseData[User.STRUCTURE_UUID_FIELD_KEY];
    const authenticationMethods = responseData[User.AUTH_METHODS_FIELD_KEY];

    const userBuilder = new UserBuilder(id, firstName, lastName, username).withEmail(email);

    userBuilder.withAuthenticationMethods(authenticationMethods);

    if (avatarImageURL) {
      userBuilder.withAvatarImageURL(avatarImageURL);
    }
    if (resetDeviceIdentifier) {
      userBuilder.withResetDeviceIdentifier(resetDeviceIdentifier);
    }
    if (structureUUID) {
      userBuilder.withStructureUUID(structureUUID);
    }

    return userBuilder.build();
  }

  private mapAuthenticationData(responseData: any): AuthenticationToken {
    return new AuthenticationToken(responseData.token, responseData.expiryTokenTimestamp);
  }

  getUserStructure(userStructureUUID: string): Observable<Structure> {
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.userStructureUrl + `${userStructureUUID}` + this.ORGANIZATION_URL_FIELD_KEY + this.environmentSettings.organizationUUID
    )
      .withParts(Parts.STRUCTURE_FIELDS)
      .build();

    return this.smartHttpModule.get<Structure>(requestUrl);
  }

  getStructures(type?: StructureType): Observable<Structure[]> {
    const requestBuilder = new UrlBuilderService(
      this.environmentSettings.structuresUrl + this.ORGANIZATION_URL_FIELD_KEY + this.environmentSettings.organizationUUID
    ).withParts(Parts.STRUCTURE_FIELDS);
    if (type) requestBuilder.withQueryParam('type', type);
    const requestUrl = requestBuilder.build();

    return this.smartHttpModule.get<any>(requestUrl);
  }

  register(userData): Observable<any> {
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.usersUrl + 'register' + this.ORGANIZATION_URL_FIELD_KEY + this.environmentSettings.organizationUUID
    )
      .withParts(Parts.AUTHENTICATION_METHODS + ', ' + Parts.VALUES)
      .build();
    return this.smartHttpModule.post(requestUrl, userData);
  }

  getCatalean(cataleanUUID): Observable<{ type: string; version: number; name: string }> {
    const requestUrl = new UrlBuilderService(this.environmentSettings.configUrl + 'integration/' + cataleanUUID).build();
    return this.smartHttpModule.get(requestUrl);
  }

  getCataleanChangelog(additionalParams?) {
    const requestUrl = new UrlBuilderService(
      this.environmentSettings.changelogUrl + this.ORGANIZATION_URL_FIELD_KEY + this.environmentSettings.organizationUUID
    ).withParts('approved');
    if (additionalParams) {
      Object.keys(additionalParams).forEach((key) => {
        requestUrl.withQueryParam(key, additionalParams[key]);
      });
    }
    return this.smartHttpModule.get(requestUrl.build());
  }

  getProduct(productId: string): Observable<any> {
    const requestUrl = new UrlBuilderService(this.environmentSettings.templateUrl + '/parse')
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withQueryParam('templateType', 'CATALEAN_2_PRODUCTS')
      .withQueryParam('UUIDType', 'MAP_CONTAINER')
      .withQueryParam('includeAllProducts', 'true')
      .withQueryParam('productUUID', productId)
      .withQueryParam('uuid', this.environmentSettings.mappingUUID)
      .build();
    return this.smartHttpModule.get<any>(requestUrl);
  }

  getWishlistByName(wishlistName: string, userUUID: string, getPrivate = true) {
    let wlName = wishlistName;
    if (wishlistName.match(/#.+/)) {
      wlName = wishlistName.replace(/#.+/, '') + '#like';
    }
    const requestUrl = new UrlBuilderService(this.environmentSettings.wishlistUrl)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withQueryParam('isPublic', 'false')
      .withQueryParam('name', encodeURIComponent(wlName))
      .withQueryParam('userUUID', userUUID + encodeURIComponent('|') + 'none')
      .withParts(Parts.EMPTY)
      .build();
    return this.smartHttpModule
      .get<Wishlist[]>(requestUrl)
      .pipe(map((r) => r.find((w) => (getPrivate ? w.name === userUUID + '_' + wishlistName : w.name === wishlistName))));
  }

  getWishlists(userUUID: string) {
    const requestUrl = new UrlBuilderService(this.environmentSettings.wishlistUrl)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withQueryParam('isPublic', 'false')
      .withQueryParam('userUUID', userUUID + encodeURIComponent('|') + 'none')
      .withParts(Parts.EMPTY)
      .build();
    return this.smartHttpModule.get<Wishlist[]>(requestUrl);
  }

  deleteWishlists(wlUUIDs: string[]) {
    const requestUrl = new UrlBuilderService(this.environmentSettings.wishlistUrl + 'multipleUpdate')
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withParts(Parts.EMPTY)
      .build();
    return this.smartHttpModule.post<any>(requestUrl, { delete: true, uuidList: wlUUIDs });
  }

  getWishlistsForUser(userUUID: string, userType: string) {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.wishlistUrl}permission/${userUUID}`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withQueryParam('subjectType', userType)
      .withParts([Parts.WISHLISTS, Parts.STRUCTURE, Parts.STRUCTURE_FIELDS].join(','))
      .build();
    return this.smartHttpModule.get<any>(requestUrl);
  }

  addEntityToWishlist(entities: { uuid: string; addictionalProperties?: { [key: string]: string } }[], wishlistUUID: string, isPublic?: boolean) {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.wishlistUrl}${wishlistUUID}`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withQueryParam('isPublic', isPublic !== undefined ? String(isPublic) : 'false')
      .build();
    return this.smartHttpModule.post<any>(requestUrl, entities);
  }

  createWishlist(wishlist: Wishlist) {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.wishlistUrl}`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withParts(Parts.ALL)
      .build();
    return this.smartHttpModule.post<Wishlist>(requestUrl, wishlist);
  }

  updateWishlist(wishlist: Wishlist) {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.wishlistUrl}${wishlist.uuid}`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withParts(Parts.ALL)
      .build();
    return this.smartHttpModule.put<Wishlist>(requestUrl, wishlist);
  }

  addViewPermissionToUserOnEntity(subjectUUID, entityUUID, entityType) {
    const permission = [
      {
        subjectUUID,
        subjectType: 'applicationUser',
        canView: true,
        entityUUID,
        entityType,
      },
    ];
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.wishlistUrl}${entityUUID}/permission`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withParts(Parts.ALL)
      .build();
    return this.smartHttpModule.post(requestUrl, permission);
  }

  updateEntityInWishlist(entity: { [key: string]: any }, wishlistUUID: string) {
    const requestUrl = new UrlBuilderService(
      `${this.environmentSettings.wishlistUrl}${wishlistUUID}/${entity.uuid}?organizationUUID=${this.environmentSettings.organizationUUID}`
    )
      .withParts(Parts.WISHLISTS)
      .build();
    return this.smartHttpModule.put(requestUrl, entity);
  }

  removeEntitiesFromWishlist(uuidList: string[], wishlistUUID: string, isPublic?: false) {
    let baseUrl = `${this.environmentSettings.wishlistUrl}${wishlistUUID}/`;
    if (uuidList.length > 1) {
      baseUrl += '/children';
    } else {
      baseUrl += uuidList[0];
    }
    const requestUrl = new UrlBuilderService(`${baseUrl}?organizationUUID=${this.environmentSettings.organizationUUID}`).withQueryParam('isPublic', isPublic !== undefined ? String(isPublic) : 'false').build();
    return uuidList.length > 1 ? this.smartHttpModule.delete(requestUrl, uuidList) : this.smartHttpModule.delete(requestUrl);
  }

  exportWishlist(wishlistUUID: string, exportProductFields?: string): Observable<string> {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.wishlistUrl}${wishlistUUID}/export`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withQueryParam('exportProductFields', exportProductFields ?? this.environmentSettings.wishlistExportProductFields?.join(','))
      .build();
    return this.smartHttpModule.get(requestUrl, 'text');
  }
  getEntities<T>(endpoint: string, additionalParams: Record<string, unknown> | undefined, parts: Parts[]): Observable<T> {
    const requestUrlBuilder = new UrlBuilderService(endpoint)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withParts(parts);

    if (additionalParams) {
      const key = Object.keys(additionalParams).find((k) => k.includes('ommunityUUID'));
      if (!!key) {
        const { communityEntityUUID, ...params } = additionalParams;
        additionalParams = params;
      }
      for (const filter of Object.keys(additionalParams)) {
        if (additionalParams[filter] !== undefined) {
          requestUrlBuilder.withQueryParam(filter, additionalParams[filter] as string);
        }
      }
    }
    return this.smartHttpModule.get(requestUrlBuilder.build());
  }

  getCollections(): Observable<Collection[]> {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.collectionsUrl}`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .withQueryParam('isPublic', 'false')
      // .withParts([Parts.EMPTY].join(','))
      .build();
    return this.smartHttpModule.get(requestUrl);
  }

  deleteCollections(collectionsUUID: string[]) {
    const body = {
      delete: true,
      uuidList: collectionsUUID,
    };
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.collectionsUrl}multipleUpdate`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .build();
    return this.smartHttpModule.post(requestUrl, body);
  }

  updateCollection(collection: Collection): Observable<DataleanUpdateEntityResponse> {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.collectionsUrl}${collection.uuid}`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .build();
    return this.smartHttpModule.put(requestUrl, collection);
  }

  createCollection(collection: Collection): Observable<Collection> {
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.collectionsUrl}`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .build();
    return this.smartHttpModule.post(requestUrl, collection);
  }

  addToCollections(body: AlbumUpdateBody){
    const requestUrl = new UrlBuilderService(`${this.environmentSettings.collectionsUrl}add`)
      .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
      .build();
    return this.smartHttpModule.post(requestUrl, body);
  }
}
