import { Injectable, OnDestroy } from '@angular/core';
import { Platform, getPlatforms } from '@ionic/angular';
import { fromEvent, Subscription, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never;
type Platforms =ArrElement<ReturnType<typeof getPlatforms>>

@Injectable({
  providedIn: 'root'
})
export class CurrentPlatformService implements OnDestroy {

  private _isPhone = new BehaviorSubject(false);
  private _isTablet = new BehaviorSubject(false);
  resizeSub?: Subscription;
  private _onResizeWindows = new BehaviorSubject<{width: number, height: number}>({width: window.innerWidth, height: window.innerHeight})

  constructor(private platform: Platform) {
    this.onResize()
    this.resizeSub = fromEvent(window, 'resize').pipe(debounceTime(50)).subscribe(() => {
      this.onResize()
      this._onResizeWindows.next({width: window.innerWidth, height: window.innerHeight})
    })
  }
  ngOnDestroy(): void {
    this.resizeSub?.unsubscribe();
  }

  get isPhone() {
    return this._isPhone.asObservable();
  }
  get isTablet() {
    return this._isTablet.asObservable();
  }
  get onResizeWindows() {
    return this._onResizeWindows
  }

  is(pl : Platforms) : boolean {
    if(pl === 'capacitor' || pl === 'cordova') {
      return this.platform.is('capacitor') || this.platform.is('electron')
    }
    return this.platform.is(pl)
  }

  ready() : Promise<string> {
    return this.platform.ready()
  }

  onResize() {
    if (window.innerWidth <= 767) {
      this._isPhone.next(true);
    } else if (window.innerWidth <= 989) {
      this._isTablet.next(true);
      this._isPhone.next(false);
    } else {
      this._isTablet.next(false);
      this._isPhone.next(false);
    }
  }
}
