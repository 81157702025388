<div class="header">
  <img class="logo-image" [src]="logo">
  <div class="navigator">
    <button class="navigation-btn prev" (click)="onPrev()" [class.disabled]="this.activeIndex === 1" [disabled]="this.activeIndex === 1"></button>
    <div class="steps-container">
      <span class="step active">{{activeIndex}}</span>
      <span class="separator">/</span>
      <span class="step all">{{(length)}}</span>
    </div>
    <button class="navigation-btn next" (click)="onNext()" [class.disabled]="this.activeIndex === this.length" [disabled]="this.activeIndex === this.length"></button>
  </div>
  <button class="close" (click)="onClose()"></button>
</div>
